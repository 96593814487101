.navBar {
  /* background-color: #c3d5e0; */
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
  color: #04210c !important;
  /* position: relative; */
}
* {
  font-family: "Rubik", sans-serif !important;
}

.innerNavBar {
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 5px solid red;
}
.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border: 5px solid red; */
  width: 100%;
}
.companylogo {
  height: 35px;
  width: 35px;
}
.title {
  font-family: "Roboto";
  font-size: 20px;
  margin-left: 10px;
}
.nav-item {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-family: "Roboto";
}
.navItems {
  /* border: 5px solid green; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.nav-item:hover {
  /* color: red; */
  cursor: pointer;
  text-shadow: 2px 2px 0px yellow;
  /* background-color: yellow; */
}

.appBar {
  background: transparent;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
  position: fixed;
}

.appBar.active {
  /* background-color: #0a6769; */
  background-color: #000;
}
@media screen and (width > 640px) and (width <= 768px) {
  .navButton {
    font-size: 20px !important;
  }
}
@media screen and (width > 768px) and (width <= 1024px) {
  .navButton {
    font-size: 20px !important;
  }
}
